import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Header from "../../components/molecules/Header";
import SideMenu from "../../components/molecules/SideMenu";
import Custom3DaysView from "../../components/molecules/Custom3DaysView";
import CustomDropdown from "../../components/atoms/CustomDropdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getUpcomingAppointmentsTherapist,
  updateJoiningStatus,
  getTherapistSlots,
} from "../../redux/therapist/therapistThunks";
import { submitTherapistProfile } from "../../redux/onboarding/onboardingThunks";
import Calendar from "react-calendar";
import { Calendar as ReactCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMediaQuery } from "react-responsive";
import chevronLeft from "../../assets/chevronLeft.svg";
import chevronRight from "../../assets/chevronRight.svg";
import chevronDown from "../../assets/chevronDown.svg";
import Message from "../../assets/messageIcon.svg";
// import Bell from "../../assets/bellFilled.svg";
import videoRecorder from "../../assets/video-recorder-white.svg";
import videoRecorderGray from "../../assets/video-recorder-gray.svg";
import arrowBack from "../../assets/arrow-left.svg";
import disablePattren from "../../assets/disable-pattren.svg";
import cross from "../../assets/x-close.svg";
import sparkle from "../../assets/sparkle.svg";
import clockRewind from "../../assets/clock-rewind.svg";
import dotsVerticle from "../../assets/dots-verticle.svg";
import cancelledIcon from "../../assets/checkbox-circle-line.svg";
import completedIcon from "../../assets/checkbox-circle-line-green.svg";
import noShowIcon from "../../assets/no-show.svg";
import InfoIcon from "../../assets/info-circle-colored.svg";
import calendar from "../../assets/calendar-gray.svg";
import googleMeet from "../../assets/google-meet.svg";
import guest from "../../assets/guest.svg";
import summary from "../../assets/summary.svg";
import userAvatar from "../../assets/user-large.svg";
import googleCalendarIcon from "../../assets/google-calendar-icon.png";

import Button from "../../components/atoms/Button";
import Tooltip from "../../components/atoms/Tooltip";
import {
  MetaTags,
  getTimeDifference,
  removeZeroTime,
} from "../../services/globalFunctions";
import Heading from "../../components/atoms/Heading";
import RescheduleComponent from "../../components/molecules/RescheduleComponent";
import TherapistProductTour from "../../components/molecules/TherapistProductTour";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/atoms/Loader";

const MyEvent = ({ event }) => {
  // const eventStyle = {
  //   borderRadius: "5px",
  //   color: "white",
  //   padding: "5px",
  // };
  const duration = event.session_duration?.split("-")[0];
  return (
    <div
      role="button"
      tabIndex="0"
      title={`${event.start_time} – ${event.end_time}: ${event.title}`}
    >
      <div
        className={`text-xs font-medium ${
          event.status === "upcoming" &&
          event.therapist_verification &&
          !event.reschedule_requested
            ? "text-white"
            : event.status === "completed"
            ? "text-success-900"
            : event.status === "cancelled"
            ? "text-danger-900"
            : event.reschedule_requested
            ? "text-warning-900"
            : "text-gray-900"
        }`}
      >
        <div className="flex">
          {event.title}{" "}
          {event.status === "cancelled" || event.status === "completed" ? (
            <img
              className="ml-1"
              src={event.status === "cancelled" ? cancelledIcon : completedIcon}
            />
          ) : null}
        </div>
      </div>
      {duration > 10 && (
        <div
          className={`${
            event.status === "upcoming" &&
            event.therapist_verification &&
            !event.reschedule_requested
              ? "text-white/80"
              : "text-gray-600"
          } text-xxs mt-0.5 `}
        >{`${event.start_time} – ${event.end_time}`}</div>
      )}

      {event.usercalendartoken?.calendar_provider === "google" && (
        <img
          src={googleCalendarIcon}
          className="w-3 h-3 absolute bottom-1 right-1"
        />
      )}
      {event.status === "no-show" && (
        <div className="shadow absolute z-1 right-1 bottom-1 text-xs text-gray-500 bg-gray-100 px-1.5 py-0.5 font-medium rounded-3xl flex items-center justify-center space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M12.5623 10.5874C12.8096 10.5817 13.0447 10.4712 13.2072 10.2809C13.3701 10.0902 13.3959 9.86079 13.4058 9.74944C13.4169 9.62463 13.4169 9.46962 13.4168 9.31191V5.68837C13.4169 5.53066 13.4169 5.37561 13.4058 5.25081C13.3959 5.13945 13.3701 4.91005 13.2072 4.71934C13.0253 4.50635 12.7524 4.39332 12.4732 4.4153C12.2231 4.43498 12.0426 4.57889 11.9569 4.65065C11.8608 4.73108 11.7513 4.8407 11.6398 4.95223L10.5002 6.09184C10.5 5.66733 10.4983 5.31273 10.4744 5.01993C10.4476 4.69204 10.3901 4.39063 10.2458 4.10752C10.0221 3.66847 9.66519 3.31152 9.22614 3.08781C8.94303 2.94356 8.64162 2.88607 8.31373 2.85928C7.99804 2.83348 7.61049 2.83349 7.14093 2.8335H4.80837L12.5623 10.5874Z"
              fill="#667085"
            />
            <path
              d="M1.57931 1.25435C1.3515 1.02654 0.982157 1.02654 0.754351 1.25435C0.526545 1.48216 0.526545 1.8515 0.754351 2.07931L1.79559 3.12055C1.3849 3.34557 1.05091 3.6893 0.837815 4.10752C0.693562 4.39063 0.636067 4.69204 0.609277 5.01993C0.583484 5.33562 0.58349 5.72315 0.583496 6.19271V8.80758C0.58349 9.27714 0.583484 9.6647 0.609277 9.98039C0.636067 10.3083 0.693562 10.6097 0.837815 10.8928C1.06152 11.3318 1.41847 11.6888 1.85752 11.9125C2.14063 12.0568 2.44204 12.1143 2.76993 12.141C3.08562 12.1668 3.47316 12.1668 3.94271 12.1668H7.14091C7.61046 12.1668 7.99804 12.1668 8.31373 12.141C8.64162 12.1143 8.94303 12.0568 9.22614 11.9125C9.51841 11.7636 9.77431 11.5556 9.97873 11.3037L12.421 13.746C12.6488 13.9738 13.0182 13.9738 13.246 13.746C13.4738 13.5182 13.4738 13.1488 13.246 12.921L1.57931 1.25435Z"
              fill="#667085"
            />
          </svg>
          <span>No Show</span>
          <div className="group relative hidden lg:block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
            >
              <path
                d="M6 8.5V6.5M6 4.5H6.005M11 6.5C11 9.26142 8.76142 11.5 6 11.5C3.23858 11.5 1 9.26142 1 6.5C1 3.73858 3.23858 1.5 6 1.5C8.76142 1.5 11 3.73858 11 6.5Z"
                stroke="#98A2B3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Tooltip
              direction="left"
              right="-right-5 after:right-[18px] before:right-4"
            >
              This session was marked as &quot;No Show&quot; by the customer
              support team.
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

// const CustomTimeGutterHeader = ({ timezone }) => {
//   return (
//     <div className="text-xs text-gray-400 flex items-center h-full justify-center">
//       {timezone}
//     </div>
//   );
// };

const CustomToolbar = ({
  onNavigate,
  children,
  bookedInfo,
  dataList,
  view,
  user,
}) => {
  const navigate = (action) => {
    onNavigate(action);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const calendarDropdownRef = useRef(null);
  const toggleCalendarDropdown = () => {
    setIsCalendarOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeCalendarDropdown = () => {
    setIsCalendarOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      calendarDropdownRef.current &&
      !calendarDropdownRef.current.contains(event.target)
    ) {
      closeCalendarDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to get the offset in minutes
  const getOffsetInMinutes = (timezone) => {
    const offsetString = moment.tz(timezone).format("Z"); // e.g., "+05:30"
    const [hours, minutes] = offsetString.split(":").map(Number);
    return hours * 60 + Math.sign(hours) * minutes; // Convert to total minutes
  };

  // Function to compare two timezones
  const compareOffsets = (timezone1, timezone2) => {
    const offset1 = getOffsetInMinutes(timezone1);
    const offset2 = getOffsetInMinutes(timezone2);
    const differenceInMinutes = Math.abs(offset1 - offset2);
    return differenceInMinutes < 60 && offset1 - offset2 != 30; // Check if difference is not greater than 1 hour
  };

  const handleDateChange = (date) => {
    // Update the selected date when it changes in the react-calendar
    const currentTimeZone = moment.tz.guess();
    const offsetDifference = compareOffsets(currentTimeZone, user.timezone);
    const newDate = moment(date).add(1, "days").toDate();
    setSelectedDate(offsetDifference ? date : newDate);
    onNavigate("date", offsetDifference ? date : newDate);
  };
  return (
    <div className="fixed bg-white pl-4 pr-6 lg:px-6 pt-6 w-full lg:w-[calc(100%-6rem)] z-20">
      <div className="flex justify-between custom-toolbar">
        <div
          className="flex items-center lg:hidden"
          onClick={() => setIsPanelOpen(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.6665 10.8802C1.6665 10.42 2.0396 10.0469 2.49984 10.0469H12.4998C12.9601 10.0469 13.3332 10.42 13.3332 10.8802C13.3332 11.3404 12.9601 11.7135 12.4998 11.7135H2.49984C2.0396 11.7135 1.6665 11.3404 1.6665 10.8802Z"
              fill="#101828"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.6665 5.88021C1.6665 5.41997 2.0396 5.04688 2.49984 5.04688H17.4998C17.9601 5.04688 18.3332 5.41997 18.3332 5.88021C18.3332 6.34045 17.9601 6.71354 17.4998 6.71354H2.49984C2.0396 6.71354 1.6665 6.34045 1.6665 5.88021Z"
              fill="#101828"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.6665 15.8802C1.6665 15.42 2.0396 15.0469 2.49984 15.0469H17.4998C17.9601 15.0469 18.3332 15.42 18.3332 15.8802C18.3332 16.3404 17.9601 16.7135 17.4998 16.7135H2.49984C2.0396 16.7135 1.6665 16.3404 1.6665 15.8802Z"
              fill="#101828"
            />
          </svg>
        </div>
        <div className="flex space-x-2 items-center">
          <button
            type="button"
            onClick={() => navigate("PREV")}
            className="shadow-none"
          >
            <img src={chevronLeft} />
          </button>
          <button
            type="button"
            onClick={() => navigate("TODAY")}
            className="hidden lg:block px-3 py-1.5 bg-white text-sm text-gray-500 font-medium border border-gray-200 rounded-lg shadow-featureCard"
          >
            Today
          </button>
          <button
            type="button"
            onClick={() => navigate("NEXT")}
            className="shadow-none"
          >
            <img src={chevronRight} />
          </button>
          <div ref={calendarDropdownRef}>
            <div
              className="text-lg lg:text-2xl flex space-x-2 cursor-pointer"
              onClick={toggleCalendarDropdown}
            >
              <div className="flex space-x-1">
                <span>{moment(dataList?.[0]?.date).format("MMM")}</span>
                <span className="font-semibold text-gray-400 hidden lg:block">
                  {moment(dataList?.[0]?.date).format(" YYYY")}
                </span>
              </div>

              <img src={chevronDown} />
            </div>
            {dataList[0]?.date && isCalendarOpen && (
              <div className="session-calendar flex justify-center left-0 right-0 lg:left-auto lg:right-auto absolute z-10 mt-2">
                <Calendar
                  className="shadow-therapistCard rounded-2xl p-4"
                  onChange={handleDateChange}
                  value={new Date(dataList[0]?.date) ?? selectedDate}
                />
              </div>
            )}
          </div>
        </div>
        <span className="flex space-x-2">{children}</span>
      </div>
      <div
        className={`${
          isPanelOpen ? "translate-x-0" : "-translate-x-full"
        } w-68 h-[calc(calc(100vh-4.5rem))] top-[4.5rem] z-10 shadow bg-white fixed transition-transform duration-300 -ml-4 lg:hidden side-panel`}
      >
        <div
          onClick={() => setIsPanelOpen(false)}
          className="p-4 flex justify-end"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15 5L5 15M5 5L15 15"
              stroke="#98A2B3"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="flex space-x-6 items-center px-4 w-full">
          <button
            type="button"
            onClick={() => navigate("PREV")}
            className="shadow-none"
          >
            <img src={chevronLeft} />
          </button>
          <button
            type="button"
            onClick={() => navigate("TODAY")}
            className="lg:hidden px-3 py-1.5 bg-white text-sm text-gray-500 font-medium border border-gray-200 rounded-lg shadow-featureCard flex-1"
          >
            Today
          </button>
          <button
            type="button"
            onClick={() => navigate("NEXT")}
            className="shadow-none"
          >
            <img src={chevronRight} />
          </button>
        </div>
        <span className="mt-4 block">{children}</span>
      </div>
      <div className="hidden lg:flex items-end space-x-1 mt-6">
        <div
          className="w-1/2"
          style={{ width: `${bookedInfo?.booked_percentage}%` }}
        >
          <div className="uppercase text-xs text-gray-500 flex justify-between">
            <span className="whitespace-nowrap">
              {view === "3 days" ? "These" : "This"} {view}
            </span>{" "}
            {bookedInfo?.booked_percentage > 0 ? (
              <span className="whitespace-nowrap ml-2">
                {" "}
                <span className="text-blue-900">
                  {bookedInfo?.booked_percentage}%
                </span>{" "}
                booked
              </span>
            ) : null}
          </div>

          <div className="h-1.5 w-full bg-blue-900/60 rounded-lg mt-2"></div>
        </div>
        <div
          className="w-1/2"
          style={{ width: `${bookedInfo?.available_percentage}%` }}
        >
          {bookedInfo?.available_percentage ? (
            <div className="uppercase text-xs text-gray-500 flex justify-end">
              <span>
                <span className="text-success-900">
                  {bookedInfo?.available_percentage}%
                </span>{" "}
                Available
              </span>
            </div>
          ) : null}

          <div className="h-1.5 w-full bg-success-900/60 rounded-lg mt-2"></div>
        </div>
      </div>
    </div>
  );
};

const Label = ({ label, date, drilldownView, onDrillDown, dataList }) => {
  if (!drilldownView) {
    const formattedDate = date ? moment(date).format("D") : null;
    const formattedDayOfWeek = date ? moment(date).format("ddd") : null;
    const sessionCount = dataList
      ?.filter((item) => item.day === formattedDayOfWeek)
      .map((item) => item.session_count)
      .reduce((total, count) => total + count, 0);
    return (
      <div title={label} className="flex space-x-1">
        <span className="w-7 h-7 lg:w-9 lg:h-9 rounded-full lg:text-xl text-gray-500 font-normal flex items-center justify-center active-date">
          {formattedDate}
        </span>
        <div className="space-y-0.5 flex flex-col text-left">
          <span className="text-xs font-semibold uppercase active-day">
            {formattedDayOfWeek}
          </span>
          <div className="text-xs text-gray-400 font-medium uppercase">
            {sessionCount} session
          </div>
        </div>
      </div>
    );
  }

  return <a onClick={onDrillDown}>{label}</a>;
};

const DateHeader = ({ label, date, drilldownView, onDrillDown, dataList }) => {
  return <Label {...{ label, date, drilldownView, onDrillDown, dataList }} />;
};

export default function TherapistSessions() {
  const user = useSelector((state) => state.app.user);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [wait, setWait] = useState(false);
  const [tourGuide, setTourGuide] = useState(true);
  // const [goToManageAvailability, setGoToManageAvailability] = useState(false);
  const [view, setView] = useState(isMobile ? "day" : "week");
  const [selectedStatus, setSelectedStatus] = useState("upcoming");
  const [selectedEmails, setSelectedEmails] = useState(undefined);
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [selectedEventProvider, setSelectedEventProvider] = useState("");
  const [modalState, setModalState] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [visibleStartDate, setVisibleStartDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [bookedInfo, setBookedInfo] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [calendarAccounts, setCalendarAccounts] = useState([]);
  const [assessmentResponse, setAssessmentResponse] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [showAll, setShowAll] = useState(false);

  moment.tz.setDefault(user.timezone);
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();

  useEffect(() => {
    if (!tourGuide) {
      dispatch(
        submitTherapistProfile({
          tour_guide: false,
        })
      );
    }
    if (user.onboarding && user.tour_guide) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // if (goToManageAvailability) {
    //   navigate("/therapist/profile/?availability");
    // }
  }, [tourGuide]);
  const eventStyleGetter = (event) => {
    // Customize styles based on event status
    const backgroundColor =
      event.usercalendartoken?.calendar_provider === "google"
        ? event.usercalendartoken?.color_index === 1
          ? "#FBE8B3"
          : "#E2C6FF"
        : event.status === "upcoming" &&
          event.therapist_verification &&
          !event.reschedule_requested
        ? "#007BD2"
        : event.status === "completed"
        ? "#EBFFE9"
        : event.status === "cancelled"
        ? "#FFF2EF"
        : "#ffffff";
    const border =
      event.usercalendartoken?.calendar_provider === "google"
        ? "0.5px solid #fff"
        : event.status === "upcoming" &&
          event.therapist_verification &&
          !event.reschedule_requested
        ? "0.5px solid #fff"
        : event.status === "completed"
        ? "0.5px solid #53C645"
        : event.status === "cancelled"
        ? "0.5px solid #FF2E00"
        : event.reschedule_requested
        ? "1px dashed #FFB03A"
        : "0.5px solid #029CF5";

    const style = {
      backgroundColor,
      border,
      borderRadius: "8px",
      padding: "4px 8px",
      display: event.status === "unavailable" ? "none" : "block",
    };

    return {
      style,
    };
  };

  const getRangeForView = (selectedView, visibleStartDate) => {
    switch (selectedView) {
      case "week":
        return [
          visibleStartDate?.startOf("week").toDate(),
          visibleStartDate?.endOf("week").toDate(),
        ];
      case "day":
        return [
          visibleStartDate?.startOf("day").toDate(),
          visibleStartDate?.endOf("day").toDate(),
        ];
      case "3 days":
        return [
          visibleStartDate?.startOf("day").toDate(),
          visibleStartDate?.add(2, "days").endOf("day").toDate(),
        ];
      // Add more cases for other views as needed
      default:
        // Return a default range if needed
        return [visibleStartDate?.toDate(), visibleStartDate?.toDate()];
    }
  };

  const onRangeChange = useCallback(
    async (range) => {
      if (range && range.length > 0) {
        const startDate = moment(range[0]).format("YYYY-MM-DD");
        const endDate = moment(range[range.length - 1]).format("YYYY-MM-DD");
        setVisibleStartDate(moment(range[0]));
        try {
          const response = await dispatch(
            getUpcomingAppointmentsTherapist({
              start_date: startDate,
              end_date: endDate,
            })
          );
          if (response.payload) {
            const { appointments, calendar_events, booked_info, data_list } =
              response.payload.data;
            const mergedAppointments = [...appointments, ...calendar_events];
            setAppointments(mergedAppointments);
            setBookedInfo(booked_info);
            setdataList(data_list);
          }
        } catch (error) {
          // Handle error if needed
          console.error("Error fetching appointments:", error);
        }
      }
    },
    [dispatch]
  );
  const handleSelectedEvent = (event, target) => {
    setSelectedEvent(event);
    setSelectedEventProvider(event.usercalendartoken?.calendar_provider);
    setModalState(true);
    setViewHistory(false);
    setAssessmentResponse(false);
    setRescheduleModal(false);
    setOpenMore(false);
    const newModalPosition = calculateModalPosition(target);
    setModalPosition(newModalPosition);
  };

  const calculateModalPosition = (target) => {
    const modalWidth = 375; // Adjust with your modal width
    const modalHeight = 532; // Adjust with your modal height

    const rect = target.getBoundingClientRect();

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate distance from the edges
    const distanceFromBottom = windowHeight - rect.bottom;
    const distanceFromRight = windowWidth - rect.right;
    const distanceFromLeft = rect.left;
    const distanceFromTop = rect.top;

    // Adjust modal position based on available space
    let top = rect.bottom;
    let left = rect.left;

    // Check if there is more space on the right
    if (distanceFromRight >= modalWidth) {
      left = rect.right; // Place modal to the right
    } else if (distanceFromLeft >= modalWidth) {
      // Check if there is more space on the left
      left = rect.left - modalWidth; // Place modal to the left
    } else {
      // If there is not enough space on both sides, prioritize the side with more space
      left =
        distanceFromRight >= distanceFromLeft
          ? rect.right
          : rect.left - modalWidth;
    }

    // Center modal vertically if there's not enough space at the top and bottom
    if (distanceFromBottom < modalHeight && distanceFromTop < modalHeight) {
      top = (windowHeight - modalHeight) / 2;
    } else if (distanceFromBottom < modalHeight) {
      // Adjust top position if there's not enough space at the bottom
      top = rect.top - modalHeight;
    }

    // Center modal horizontally if there's not enough space at the left and right
    if (distanceFromLeft < modalWidth && distanceFromRight < modalWidth) {
      left = (windowWidth - modalWidth) / 2;
    }

    return { top: top, left: left };
  };

  const handleCloseModal = () => {
    setModalState(false);
  };
  const triggerViewChange = () => {
    handleViewChange(view);
  };
  const handleViewChange = (selectedView) => {
    setView(selectedView);
    const rangeForView = getRangeForView(selectedView, visibleStartDate);
    onRangeChange(rangeForView);
  };
  const handleStatusChange = (status) => setSelectedStatus(status);
  const handleEmailChange = (newEmails) => setSelectedEmails(newEmails);

  const filteredAppointments = useMemo(() => {
    let filtered = appointments;
    if (selectedStatus !== "All Sessions") {
      filtered = filtered.filter(
        (appointment) => appointment.status === selectedStatus
      );
    }
    if (selectedEmails?.length > 0) {
      filtered = filtered.filter((appointment) => {
        return (
          !appointment.usercalendartoken ||
          selectedEmails.includes(appointment.usercalendartoken.calendar_email)
        );
      });
    } else {
      filtered = filtered.filter(
        (appointment) => !appointment?.usercalendartoken?.calendar_email
      );
    }
    return filtered;
  }, [selectedStatus, selectedEmails, appointments]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getUpcomingAppointmentsTherapist());
        if (response.payload) {
          const {
            appointments,
            calendar_events,
            booked_info,
            data_list,
            calendar_accounts,
          } = response.payload.data;
          const mergedAppointments = [...appointments, ...calendar_events];
          const calendarEmails =
            calendar_accounts?.length > 0 &&
            calendar_accounts.map((item) => item.calendar_email);
          setAppointments(mergedAppointments);
          setBookedInfo(booked_info);
          setdataList(data_list);
          setCalendarAccounts(calendarEmails);
          setSelectedEmails(calendarEmails);
          setVisibleStartDate(moment(data_list[0].date));
        }
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, [dispatch]);

  const slotPropGetter = (date) => {
    // Check if the date is in the disabled time slots
    const disabledHours = appointments?.filter(
      (appointment) => appointment.status === "unavailable"
    );
    const isDisabled = disabledHours?.some(
      (slot) =>
        moment(date).isSameOrAfter(slot.start) &&
        moment(date).isBefore(slot.end)
    );

    return {
      className: isDisabled ? "disabled-slot" : "", // Apply a CSS class for styling
      style: isDisabled ? { background: `url(${disablePattren})` } : {}, // Apply inline styles for further customization
    };
  };

  const { views, messages, ...otherProps } = useMemo(
    () => ({
      views: {
        week: true,
        day: true,
        "3 days": Custom3DaysView,
      },
      messages: {
        week: "Week",
        day: "Day",
        "3 days": "3 Days", // Add the label for 3 days view
      },
      // ... other props
    }),
    []
  );

  const handleJoiningStatus = async (status, id, buttonId) => {
    setWait((prevLoaders) => ({
      ...prevLoaders,
      [buttonId]: true,
    }));
    if (status === "yes") {
      try {
        // Dispatch the updateJoiningStatus action
        const response = await dispatch(
          updateJoiningStatus({
            appointment_id: id,
            joining_status: { joining: true },
          })
        );

        if (response.payload) {
          await handleViewChange(view);
          setModalState(false);
          setWait((prevLoaders) => ({
            ...prevLoaders,
            [buttonId]: false,
          }));
        }
      } catch (error) {
        // Handle any errors that occur during the dispatch
        console.error("Error updating joining status:", error);
      }
    } else {
      try {
        // Dispatch the updateJoiningStatus action
        setLoader(true);
        const response = await dispatch(
          getTherapistSlots({
            appointment_id: id,
          })
        );

        if (response.payload) {
          setRescheduleModal(response.payload.data);
          setWait((prevLoaders) => ({
            ...prevLoaders,
            [buttonId]: false,
          }));
          setLoader(false);
        }
      } catch (error) {
        // Handle any errors that occur during the dispatch
        console.error("Error updating joining status:", error);
      }
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const customStyles = {
    content: {
      maxWidth: isMobile ? "100%" : "372px",
      width: "100%",
      top: isMobile ? "auto" : `${modalPosition.top}px`,
      left: isMobile ? "0" : `${modalPosition.left}px`,
      border: "none",
      borderRadius: isMobile ? "16px 16px 0 0" : "16px",
      padding: "0",
      height: "100%",
      maxHeight: "532px",
      overflow: "auto",
      bottom: isMobile ? "0" : "auto",
    },
    overlay: {
      background: isMobile ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0)",
      zIndex: "30",
    },
  };

  const handleJoinSession = () => {
    if (getTimeDifference(selectedEvent?.appointment_time) > 15) {
      // toast.error("You can not join early!");
    } else
      window.open(
        `/dashboard/session/?patient_id=${selectedEvent?.patient}&appointment=${selectedEvent?.id}&therapist_id=${selectedEvent?.therapist}`
      );
  };
  // if (appointments.length === 0) return <Loader />;
  return (
    <div>
      <MetaTags
        title="Sessions | Fitcy Health"
        description="Manage your Fitcy Health sessions"
        url="/therapist/sessions"
      />
      <div className="step-1"></div>
      <div className="step-2"></div>
      <Header
        className="border-b border-gray-200 fixed z-30 bg-white"
        dashboard={true}
        app="THERAPIST"
      />
      <div className={`lg:flex lg:pl-24 pb-20 lg:pb-0 pt-[4.5rem]`}>
        <SideMenu
          app="THERAPIST"
          className="lg:-mt-[1px] left-0 lg:top-[4.45rem]"
        />
        <div className="flex-1 relative">
          {appointments.length > 0 || dataList.length > 0 ? (
            <div className="w-full h-full overflow-auto">
              <ReactCalendar
                localizer={localizer}
                events={filteredAppointments}
                startAccessor={(event) => {
                  return moment(event.start).tz(user.timezone).toDate();
                }}
                endAccessor={(event) => {
                  return moment(event.end).tz(user.timezone).toDate();
                }}
                style={{
                  flexShrink: "0",
                }}
                views={views}
                onView={(selectedView) => handleViewChange(selectedView)}
                view={view}
                messages={messages}
                // defaultView={isMobile ? "day" : "week"}
                components={{
                  event: (props) => <MyEvent event={props.event} />,
                  toolbar: (props) => (
                    <CustomToolbar
                      label={localizer.format(moment(), "MMM YYYY")}
                      onNavigate={props.onNavigate}
                      bookedInfo={bookedInfo}
                      dataList={dataList}
                      view={view}
                      user={user}
                    >
                      <CustomDropdown
                        views={calendarAccounts}
                        view={["Google Calendar"]}
                        dropdown="email"
                        selectedEmails={selectedEmails}
                        onChange={handleEmailChange}
                        className={`account-filters 
                        ${calendarAccounts.length > 0 ? "block" : "hidden"}
                      `}
                      />
                      <CustomDropdown
                        views={[
                          "All Sessions",
                          "upcoming",
                          "completed",
                          "cancelled",
                        ]}
                        onChange={handleStatusChange}
                        view={selectedStatus}
                        dropdown="status"
                        className="session-filters"
                      />
                      <CustomDropdown
                        views={views}
                        onChange={handleViewChange}
                        view={view}
                        className="view-filters"
                      />
                    </CustomToolbar>
                  ),
                  week: {
                    header: (props) => (
                      <DateHeader dataList={dataList} {...props} />
                    ),
                  },
                  day: {
                    header: (props) => (
                      <DateHeader dataList={dataList} {...props} />
                    ),
                  },
                  "3 days": {
                    header: (props) => (
                      <DateHeader dataList={dataList} {...props} />
                    ),
                  },
                  // timeGutterHeader: () => (
                  //   <CustomTimeGutterHeader timezone={user.timezone} />
                  // ),
                }}
                onSelectEvent={(event, e) =>
                  handleSelectedEvent(event, e.target)
                }
                onRangeChange={onRangeChange}
                slotPropGetter={slotPropGetter}
                eventPropGetter={eventStyleGetter}
                {...otherProps}
              />
            </div>
          ) : (
            <div className="h-[calc(100vh-4.5rem)]">
              <Loader />
            </div>
          )}
          <Modal
            isOpen={modalState}
            onRequestClose={handleCloseModal}
            modalPosition={modalPosition}
            style={customStyles}
            className={`${
              isMobile && modalState
                ? "translate-y-0"
                : "translate-y-full lg:translate-y-0"
            } w-full transform transition-transform fixed bottom-0 outline-none bg-white shadow-calendarPopup`}
          >
            {rescheduleModal ? (
              <div className="p-6">
                <Heading variant="h3">Request for Reschedule</Heading>
                <div className="p-2 mt-6 bg-[#FFF4E8] rounded-md text-sm text-gray-500 flex items-center">
                  <img src={InfoIcon} className="mr-2.5" />
                  {`This will only send a request to the Client for reschedule. You'll be notified when the Client reschedules the session.`}
                </div>
                <div className="mt-6 text-sm">Your Proposal</div>
                <RescheduleComponent
                  data={rescheduleModal}
                  appointmentId={selectedEvent?.id}
                  handleCloseModal={handleCloseModal}
                  triggerViewChange={triggerViewChange}
                />
                <div
                  onClick={handleCloseModal}
                  className="text-sm text-center text-gray-500 mt-3 cursor-pointer"
                >
                  Cancel
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`p-6  ${
                    selectedEventProvider &&
                    selectedEvent?.usercalendartoken?.color_index === 1
                      ? "bg-[#FFF6DE]"
                      : selectedEvent?.usercalendartoken?.color_index === 2
                      ? "bg-[#E2C6FF]"
                      : "bg-blue-50"
                  }`}
                >
                  <div className="flex justify-end space-x-4">
                    {selectedEvent?.status === "upcoming" &&
                    !selectedEvent?.reschedule_requested &&
                    !selectedEventProvider ? (
                      <div className="relative">
                        <img
                          src={dotsVerticle}
                          className="cursor-pointer"
                          onClick={() => setOpenMore(!openMore)}
                        />
                        {openMore && (
                          <div className="absolute p-4 bg-white shadow-therapistCard -right-8 w-68 rounded-2xl top-8">
                            <div
                              className="flex items-center space-x-2 cursor-pointer"
                              onClick={() =>
                                handleJoiningStatus("no", selectedEvent?.id)
                              }
                            >
                              {loader ? (
                                <div className="flex items-center justify-center">
                                  <div className="border-t-2 border-teal-300 border-solid rounded-full h-6 w-6 animate-spin"></div>
                                </div>
                              ) : (
                                <img src={calendar} />
                              )}

                              <span className="text-sm text-gray-500">
                                Reschedule session
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    <img
                      src={cross}
                      onClick={handleCloseModal}
                      className="cursor-pointer"
                    />
                  </div>
                  <div className="flex items-center space-x-1">
                    {selectedEvent?.sr_num && (
                      <div className="px-2 py-0.5 bg-blue-100 rounded-2xl text-blue-600 text-xxs font-semibold max-w-max flex items-center">
                        <img src={clockRewind} className="mr-0.5" />
                        Session Number: {selectedEvent?.sr_num}
                      </div>
                    )}
                    {selectedEvent?.status === "cancelled" ||
                    selectedEvent?.status === "completed" ||
                    selectedEvent?.reschedule_requested ? (
                      <div
                        className={`${
                          selectedEvent?.status === "cancelled"
                            ? "bg-danger-900 text-danger-100"
                            : selectedEvent?.status === "completed"
                            ? "bg-success-900 text-success-100"
                            : "bg-[#E48A02] text-warning-100"
                        } px-1 py-0.5 rounded-2xl text-xxs font-semibold leading-tight`}
                      >
                        {selectedEvent?.status === "cancelled"
                          ? "Session Cancelled"
                          : selectedEvent?.status === "completed"
                          ? "Session Completed"
                          : "Reschedule Requested"}
                      </div>
                    ) : null}
                  </div>

                  <div className="text-xl font-medium mt-2">
                    {!selectedEventProvider && (
                      <span className="text-gray-400 font-normal">
                        Session with
                      </span>
                    )}{" "}
                    {selectedEvent?.title}
                  </div>
                  <div className="tetx-gray-600 text-sm">
                    {selectedEvent?.session_date} ⋅ {selectedEvent?.start_time}{" "}
                    – {selectedEvent?.end_time}
                  </div>
                </div>
                {assessmentResponse ? (
                  <div className="p-6">
                    <img
                      src={arrowBack}
                      onClick={() => setAssessmentResponse(!assessmentResponse)}
                      className="cursor-pointer"
                    />
                    <div className="mt-3 font-medium">
                      {`Client's Assessment Response`}
                    </div>
                    {selectedEvent?.assessment?.results.length > 0 ? (
                      <ul className="space-y-3">
                        {selectedEvent?.assessment?.results?.map(
                          (item, index) => {
                            return (
                              <li
                                className="px-4 py-3 rounded-2xl shadow-calendarPopupInner"
                                key={index}
                              >
                                <span className="text-sm text-gray-600">
                                  {item.question_title}
                                </span>
                                <div className="mt-0.5">
                                  {item.answer_title}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    ) : (
                      <div className="text-sm text-gray-500 py-8 text-center">
                        No Assessment Response available
                      </div>
                    )}
                  </div>
                ) : viewHistory ? (
                  <div className="p-6">
                    <img
                      src={arrowBack}
                      onClick={() => setViewHistory(!viewHistory)}
                      className="cursor-pointer"
                    />
                    <div className="mt-3 font-medium">Session History</div>
                    <Tabs className="w-full plane-tabs mt-3">
                      <TabList className="flex space-x-2">
                        <Tab className="px-2 py-1 text-sm text-gray-400 rounded-3xl font-medium border border-gray-200 flex items-center cursor-pointer">
                          Past
                        </Tab>
                        <Tab className="px-2 py-1 text-sm text-gray-400 rounded-3xl font-medium border border-gray-200 flex items-center cursor-pointer">
                          Upcoming
                        </Tab>
                      </TabList>

                      <TabPanel className="mt-6">
                        {selectedEvent?.session_history.past.length > 0 && (
                          <div className="space-y-2">
                            {selectedEvent?.session_history.past.map(
                              (item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="px-3 py-2 border border-gray-200 rounded-lg"
                                  >
                                    <div className="text-sm font-medium text-gray-700 flex items-center">
                                      Session{" "}
                                      {item.status === "cancelled"
                                        ? "Cancelled"
                                        : item.sr_num}
                                      <img
                                        className="ml-1"
                                        src={
                                          item.status === "cancelled"
                                            ? cancelledIcon
                                            : item.status === "no-show"
                                            ? noShowIcon
                                            : completedIcon
                                        }
                                      />
                                    </div>
                                    <div className="flex justify-between text-sm mt-0.5">
                                      <div className="text-gray-600">
                                        {item.session_date.slice(0, 3)},{" "}
                                        {item.session_day} {item.session_month}{" "}
                                        ⋅ {removeZeroTime(item.start_time)} –{" "}
                                        {removeZeroTime(item.end_time)}
                                      </div>
                                      <span
                                        className={`${
                                          item.status === "completed"
                                            ? "text-success-900"
                                            : item.status === "no-show"
                                            ? "text-gray-500"
                                            : "text-danger-900"
                                        } capitalize `}
                                      >
                                        {item.status === "no-show"
                                          ? "No Show"
                                          : item.status}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </TabPanel>
                      <TabPanel className="mt-6">
                        {selectedEvent?.session_history.upcoming.length > 0 && (
                          <div className="space-y-2">
                            {selectedEvent?.session_history.upcoming.map(
                              (item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`${
                                      item.therapist_verification &&
                                      item.status !== "cancelled"
                                        ? "bg-blue-600"
                                        : ""
                                    } ${
                                      item.status === "cancelled"
                                        ? "border-gray-200"
                                        : "border-blue-500"
                                    } px-3 py-2 border rounded-lg`}
                                  >
                                    <div
                                      className={`${
                                        item.therapist_verification &&
                                        item.status !== "cancelled"
                                          ? "text-blue-100"
                                          : "text-gray-700"
                                      }  text-sm font-medium flex items-center`}
                                    >
                                      Session{" "}
                                      {item.status === "cancelled"
                                        ? "Cancelled"
                                        : item.sr_num}
                                      <img
                                        className="ml-1"
                                        src={
                                          item.status === "cancelled"
                                            ? cancelledIcon
                                            : completedIcon
                                        }
                                      />
                                    </div>
                                    <div className="flex justify-between text-sm mt-0.5">
                                      <div
                                        className={
                                          item.therapist_verification &&
                                          item.status !== "cancelled"
                                            ? "text-white/80"
                                            : "text-gray-600"
                                        }
                                      >
                                        {item.session_date.slice(0, 3)},{" "}
                                        {item.session_day} {item.session_month}{" "}
                                        ⋅ {removeZeroTime(item.start_time)} –{" "}
                                        {removeZeroTime(item.end_time)}
                                      </div>
                                      <span
                                        className={`${
                                          item.status === "completed"
                                            ? "text-success-900"
                                            : item.status === "cancelled"
                                            ? "text-danger-900"
                                            : item.therapist_verification
                                            ? "text-white/80"
                                            : "text-gray-600"
                                        } capitalize`}
                                      >
                                        {item.status}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </TabPanel>
                    </Tabs>
                  </div>
                ) : selectedEventProvider ? (
                  <div className="p-6 space-y-4 overflow-auto max-h-[calc(100%-12rem)]">
                    {selectedEvent.hangout_link && (
                      <div className="flex space-x-4">
                        <img src={googleMeet} />
                        <a
                          className="bg-[#0071DA] text-white px-5 py-2.5 flex justify-center rounded-md text-sm font-medium hover:opacity-90"
                          href={selectedEvent.hangout_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Join with Google Meet
                        </a>
                      </div>
                    )}
                    {selectedEvent.summary && (
                      <div className="flex space-x-4">
                        <img src={summary} />
                        <div className="text-xs font-normal text-gray-500">
                          {selectedEvent.summary}
                        </div>
                      </div>
                    )}
                    {selectedEvent.attendees && (
                      <div>
                        <div className="flex space-x-4 text-sm">
                          {" "}
                          <img src={guest} />
                          <div>{selectedEvent.attendees.length} Guest</div>
                        </div>

                        <ul className="space-y-1.5 mt-2 pl-8">
                          {(showAll
                            ? selectedEvent.attendees
                            : selectedEvent.attendees.slice(0, 3)
                          ).map((attendee, index) => {
                            return (
                              <li
                                key={index}
                                className="text-sm font-normal text-gray-500 flex space-x-2 items-start"
                              >
                                <div className="p-1 items-center justify-center bg-white border rounded-full">
                                  <img
                                    src={userAvatar}
                                    className="w-3 h-3 object-cover rounded-full"
                                  />
                                </div>

                                <div>
                                  {attendee.email}
                                  {attendee.organizer && (
                                    <div className="text-xs">Organizer</div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                          {selectedEvent.attendees.length > 3 && (
                            <li className="text-sm mt-1">
                              <button
                                onClick={toggleShowAll}
                                className="text-gray-500 hover:underline shadow-none font-medium"
                              >
                                {showAll
                                  ? "Show Less"
                                  : `${
                                      selectedEvent.attendees.length - 3
                                    } more.`}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                    <div className="flex items-center space-x-4 absolute inset-x-0 bottom-0 py-4 px-6 bg-gray-100">
                      <img src={googleCalendarIcon} className="w-5 h-5" />{" "}
                      <div className="text-xs">
                        <div>Google Calendar Event</div>
                        <div className="text-gray-500">
                          {selectedEvent.usercalendartoken?.calendar_email}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-6">
                    {selectedEvent?.status === "cancelled" ||
                    selectedEvent?.status === "completed" ||
                    selectedEvent?.status === "no-show" ||
                    selectedEvent?.reschedule_requested ? null : (
                      <div className="flex justify-between px-4 py-3 rounded-2xl shadow-calendarPopupInner">
                        <span className="text-sm text-gray-600">
                          Are you joining?
                        </span>
                        <div className="flex space-x-2.5">
                          <button
                            className={`${
                              selectedEvent?.therapist_verification
                                ? "bg-blue-600 hover:bg-blue-900 text-white"
                                : "text-gray-500"
                            } px-1.5 py-0.5 rounded-3xl text-xs w-8`}
                            onClick={() =>
                              handleJoiningStatus("yes", selectedEvent?.id, 1)
                            }
                          >
                            {wait[1] ? (
                              <div className="flex items-center justify-center">
                                <div className="border-t-2 border-teal-300 border-solid rounded-full h-3 w-3 animate-spin"></div>
                              </div>
                            ) : (
                              "Yes"
                            )}
                          </button>
                          <button
                            className="px-1.5 py-0.5 bg-white text-gray-500 rounded-3xl text-xs w-8"
                            onClick={() =>
                              handleJoiningStatus("no", selectedEvent?.id, 2)
                            }
                          >
                            {wait[2] ? (
                              <div className="flex items-center justify-center">
                                <div className="border-t-2 border-teal-300 border-solid rounded-full h-3 w-3 animate-spin"></div>
                              </div>
                            ) : (
                              "No"
                            )}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="px-4 py-3 rounded-2xl shadow-calendarPopupInner mt-3">
                      {(selectedEvent?.patient_info?.gender ||
                        selectedEvent?.patient_info?.age ||
                        selectedEvent?.patient_info?.country ||
                        selectedEvent?.patient_info?.medical_conditions) && (
                        <div className="mb-3">
                          <span className="text-sm text-gray-600 flex items-center">
                            <img src={sparkle} className="mr-2" />
                            About the Client
                          </span>

                          <ul className="font-medium text-sm flex divide-x divide-x-gray-200 mt-2">
                            {selectedEvent?.patient_info.gender && (
                              <li className="px-2 py-1">
                                {selectedEvent?.patient_info.age}{" "}
                                {selectedEvent?.patient_info.gender}
                              </li>
                            )}

                            {selectedEvent?.patient_info?.country && (
                              <li className="px-2 py-1">
                                {selectedEvent?.patient_info?.country}
                              </li>
                            )}
                            {selectedEvent?.patient_info
                              ?.medical_conditions?.[0]?.label && (
                              <li className="px-2 py-1">
                                {
                                  selectedEvent?.patient_info
                                    ?.medical_conditions?.[0]?.label
                                }
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                      <div
                        className="text-sm text-gray-600 flex justify-between px-3 py-1.5 cursor-pointer"
                        onClick={() =>
                          setAssessmentResponse(!assessmentResponse)
                        }
                      >
                        <span>{`Read Client's Assessment Response`}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M12.1132 10.0002L7.74404 5.60119C7.41865 5.27306 7.41865 4.74106 7.74404 4.41293C8.06943 4.0848 8.59699 4.0848 8.92237 4.41293L13.8806 9.40594C14.2061 9.73412 14.2061 10.2662 13.8806 10.5944L8.92237 15.5874C8.59699 15.9155 8.06943 15.9155 7.74404 15.5874C7.41865 15.2593 7.41865 14.7273 7.74404 14.3991L12.1132 10.0002Z"
                            fill="#667085"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="flex justify-between px-4 py-3 rounded-2xl shadow-calendarPopupInner mt-3 cursor-pointer"
                      onClick={() => setViewHistory(!viewHistory)}
                    >
                      <span className="text-sm text-gray-600">
                        View Session History
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M12.1132 10.0002L7.74404 5.60119C7.41865 5.27306 7.41865 4.74106 7.74404 4.41293C8.06943 4.0848 8.59699 4.0848 8.92237 4.41293L13.8806 9.40594C14.2061 9.73412 14.2061 10.2662 13.8806 10.5944L8.92237 15.5874C8.59699 15.9155 8.06943 15.9155 7.74404 15.5874C7.41865 15.2593 7.41865 14.7273 7.74404 14.3991L12.1132 10.0002Z"
                          fill="#667085"
                        />
                      </svg>
                    </div>
                    <div className="flex items-center space-x-4 absolute bottom-6 inset-x-6">
                      <img
                        src={Message}
                        className="cursor-pointer"
                        onClick={() => navigate(`/therapist/messages`)}
                      />
                      {/* <img src={Bell} /> */}
                      <Button
                        onClick={() =>
                          selectedEvent?.status === "cancelled" ||
                          selectedEvent?.status === "completed" ||
                          selectedEvent?.status === "no-show" ||
                          getTimeDifference(selectedEvent?.appointment_time) >
                            15
                            ? {}
                            : handleJoinSession()
                        }
                        className={`${
                          selectedEvent?.status === "cancelled" ||
                          selectedEvent?.status === "completed" ||
                          selectedEvent?.status === "no-show" ||
                          getTimeDifference(selectedEvent?.appointment_time) >
                            15
                            ? "bg-gray-200 text-gray-500 cursor-auto border-gray-300"
                            : "bg-blue-900 hover:bg-blue-600 text-white border-blue-600"
                        } px-5 py-2.5 !rounded-xl flex space-x-2 items-center flex-1 justify-center border`}
                        disabled={
                          selectedEvent?.status === "cancelled" ||
                          selectedEvent?.status === "completed" ||
                          selectedEvent?.status === "no-show" ||
                          getTimeDifference(selectedEvent?.appointment_time) >
                            15
                            ? true
                            : false
                        }
                      >
                        <img
                          src={
                            selectedEvent?.status === "cancelled" ||
                            selectedEvent?.status === "completed" ||
                            selectedEvent?.status === "no-show" ||
                            getTimeDifference(selectedEvent?.appointment_time) >
                              15
                              ? videoRecorderGray
                              : videoRecorder
                          }
                        />
                        <span>Join Session</span>
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Modal>
        </div>
      </div>
      {user.onboarding && user.tour_guide && (
        <TherapistProductTour
          setTourGuide={(value) => setTourGuide(value)}
          // setGoToManageAvailability={(value) =>
          //   setGoToManageAvailability(value)
          // }
        />
      )}
    </div>
  );
}
